import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import TruncatedTextWithTooltip from 'components/ui-components-cape/TurncatedTextWithTooltip';
import Typography from 'components/ui-components-v2/Typography';

import '../styles/info.scss';

interface Props {
    title: string;
    subtitle: string;
    icon?: string;
    small?: boolean;
    children?: React.ReactElement;
}

const CreativeManagementAssetTileInfo = ({ title, icon = 'star', subtitle, small, children }: Props) => {
    return (
        <div className="creative-management-asset-tile-info ">
            {icon && <Icon color="disabled">{icon}</Icon>}
            <div className="creative-management-asset-tile-info__text">
                <TruncatedTextWithTooltip dataCy="creativeManagement-assetTileTitle-div" variant={small ? 'h5' : 'h4'}>
                    {title}
                </TruncatedTextWithTooltip>
                <Typography variant="body2" color="textSecondary" noWrap className="template-management-asset-tile-info__subtitle">
                    {subtitle}
                </Typography>
            </div>
            {children && children}
        </div>
    );
};

export default CreativeManagementAssetTileInfo;
